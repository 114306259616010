import { createI18n } from 'vue-i18n';
import zhCN from './config/zh-cn';
import enUS from './config/en-us';
import jaJP from './config/ja-jp';
import { arrayToObject } from '@/utils/util';
import { siteConfig } from '@/config/default.config';

export const langList = [
  { key: 'zh', file: zhCN, title: '中文', lang: 'zh-CN' },
  { key: 'en', file: enUS, title: 'English', lang: 'en-US' },
  { key: 'jp', file: jaJP, title: '日本語', lang: 'ja-JP' },
];

export default createI18n({
  locale: localStorage.getItem('lang') || siteConfig.defaultLang,
  globalInjection: true,
  legacy: false,
  messages: arrayToObject(langList, 'key', 'file') as any,
});