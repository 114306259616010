import router from '@/router';
import NProgress from 'nprogress';
import { message } from 'ant-design-vue';
import { baseURL, successCode, notLoginCode, notFoundCode, repeatRequestTime } from '@/config/default.config';
import { getToken, removeToken } from '@/utils/auth';
import { Base64 } from 'js-base64';
import { md5 } from 'js-md5';

let requestMap = new Map();

interface MyRequestInit extends RequestInit {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  params?: Object
  isSerialize?: boolean
  isFormData?: boolean
  responseType?: 'json' | 'text' | 'blob' | 'arraybuffer'
}

export default async function (url = '', requestConfig: MyRequestInit) {
  if (!/http(s)?:\/\//.test(url)) {
    url = baseURL + url; // 请求地址的拼接
  }
  let {
    method,
    params = {},
    headers = {},
    isSerialize = false,
    isFormData = false,
    responseType,
    ...config
  } = requestConfig;

  const urlmd5 = md5(url + JSON.stringify(params));
  const val = requestMap.get(urlmd5);
  const now = Date.now();
  if (val && now - val < repeatRequestTime) {
    console.log('阻止请求');
    return Promise.reject({
      msg: '操作频繁，请稍后再试'
    });
  }
  requestMap.set(urlmd5, now);

  headers = Object.assign({
    'Accept': '*/*',
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: `Basic ${Base64.encode('operate:operate-secret')}`,
  }, headers);

  const token = getToken();
  if (token) {
    headers['Running-Auth'] = `bearer ${token}`;
  }

  let _config: RequestInit = {
    method: method, // 请求方式小写转换成大写
    headers: headers,
    credentials: 'include',
    mode: 'cors', // 用来决定是否允许跨域请求  值有 三个 same-origin，no-cors（默认）以及 cores;
    cache: 'no-store', // 是否缓存请求资源 可选值有 default 、 no-store 、 reload 、 no-cache 、 force-cache 或者 only-if-cached。
  }

  _config = Object.assign(_config, config);

  let dataStr = ''; // 数据拼接字符串
  if (method === 'GET' || (method == 'POST' && isSerialize)) {
    params = params || {};
    Object.keys(params).forEach(key => {
      dataStr += key + '=' + params[key] + '&';
    });
    dataStr = dataStr.substring(0, dataStr.length - 1);
    if (dataStr !== '') {
      url = url + '?' + dataStr;
    }
  } else if (isFormData) {
    // TODO 这里需要处理，不知道为什么没有把文件传给后端
    _config.body = params as FormData;
  } else {
    _config.body = JSON.stringify(params);
  }

  try {
    NProgress.start();
    const response = await fetch(url, _config);
    NProgress.done();
    if (responseType === 'blob') {
      if (response.status === notLoginCode) {
        goLogin();
        return Promise.reject();
      }
      return Promise.resolve(response.blob());
    }
    const json = await response.json();
    if (response.status === notLoginCode) {
      goLogin();
      return Promise.reject(json);
    }
    if (json.code === notLoginCode) {
      goLogin();
      return Promise.reject(json);
    }
    if (json.code && json.code !== successCode) {
      return Promise.reject(json);
    } else {
      return Promise.resolve(json);
    }
  } catch (err) {
    NProgress.done();
    return Promise.reject(err);
  }
}

function goLogin() {
  message.warning('登录凭证失效');
  setTimeout(() => {
    router.push({ path: '/login' });
  }, 1000);
}