<template>
  <a-layout style="height: 100vh">
    <a-drawer :width="sideWidth" placement="left" :visible="!collapsed" :closable="false" :maskClosable="true"
      :bodyStyle="drawerMenuStyle" @close="toggleCollapsed()" v-if="isMobile">
      <div class="logo" />
      <app-menu></app-menu>
    </a-drawer>
    <a-layout-sider v-model:collapsed="collapsed" :width="sideWidth" :trigger="null" collapsible v-else>
      <div class="logo" />
      <app-menu></app-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="app-header" :style="{ height: `${headerHeight}px` }">
        <app-header></app-header>
      </a-layout-header>
      <a-layout class="scrollbar" style="min-width: 480px; padding: 10px; overflow: hidden;">
        <a-layout-content ref="pageRef" class="page-content">
          <router-view class="page-wrap" v-slot="{ Component }">
            <transition name="fade-transform" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup>
import { throttle } from 'lodash-es';
import AppHeader from './app-header.vue';
import AppMenu from './app-menu.vue';
import { useSiteStore } from '@/store/site';
import { useDictStore } from '@/store/dict';
import { useDeptStore } from '@/store/dept';
import { useGlobalStore } from '@/store/global';
import useMenuCollapse from '@/hooks/useMenuCollapse';

const site = useSiteStore();
const dict = useDictStore();
const dept = useDeptStore();
const global = useGlobalStore();
const pageRef = ref(null);
const amapKeys = ref([]);

const sideWidth = computed(() => site.sideWidth);
const headerHeight = computed(() => site.headerHeight);
const isMobile = computed(() => site.pageWidth < 768);

const { collapsed, toggleCollapsed } = useMenuCollapse();

const drawerMenuStyle = reactive({
  padding: '0 !important',
  backgroundColor: '#000c17',
});

onMounted(() => {
  onResize();
  global.getCarList();
  global.getDriverList();
  dept.getTreeDept();
  dict.getBizTree().then(() => {
    amapKeys.value = dict.getDictByCode('amap_key', 1) || [];
  });
  window.addEventListener('resize', throttle(onResize, 500));
});

onUnmounted(() => {
  window.removeEventListener('resize', throttle(onResize, 500));
});

function onResize() {
  if (!pageRef.value) return;
  const $el = pageRef.value.$el;
  if (!$el) return;
  site.setPageWidth($el.offsetWidth);
  site.setPageHeight($el.offsetHeight);
}

function getAMapKey(datas) {
  let len = datas.length;
  const r = Math.floor(Math.random() * len);
  return datas[r];
}

watch(() => isMobile.value, () => {
  toggleCollapsed(false);
}, {
  immediate: true
});

watch(() => amapKeys.value, (newVal) => {
  if (newVal.length === 0) return;
  const data = getAMapKey(newVal);
  window.localStorage.setItem('amapkey', data.dictValue);
  window.localStorage.setItem('amapcode', data.dictKey);
}, {
  deep: true,
  immediate: true
});

defineOptions({
  name: 'Layout',
});
</script>

<style lang="less" scoped>
.logo {
  height: 56px;
  border-bottom: 1px solid #3c3c3c;
  background: url('/img/logo.png') no-repeat center;
  background-size: 134px 32px;
  transition: all 0.36s;
}

.ant-layout-sider-collapsed {
  .logo {
    width: 80px !important;
    min-width: 80px !important;
    background-image: url('/img/xlogo.png');
    background-size: 36px auto;
  }

  :deep(.anticon) {
    font-size: 24px !important;
  }
}

.app-header {
  display: flex;
  background: #fff;
  padding: 0;
}

.tags-view {
  display: flex;
  width: 100%;
  height: 34px;
}

.page-content {
  flex: none;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
}
</style>