export default {
  global: {
    userCenter: '个人中心',
    userSetting: '个人设置',
    versionManage: '版本管理',
    logout: '退出登录',
  },
  login: {
    headerText: 'Ant Design 是西湖区最具影响力的 Web 设计规范',
    tabAccount: '账号登录',
    tabPhone: '手机号登录',
    btn: '登录',
    getcode: '获取验证码',
    remember: '记住密码',
    forgot: '忘记密码',
    usernameRule: '请输入用户名！',
    passwordRule: '请输入密码！',
    phoneRule: '请输入手机号！',
    codeRule: '请输入验证码！',
    usernamePlaceholder: '账号：admin',
    passwordPlaceholder: '密码：admin或123456',
    phoneNumPlaceholder: '手机号',
    phoneCodePlaceholder: '验证码',
    loginFail: '登录失败',
  },
}