import APIs from '../urls';
import fetch from '@/utils/fetch';

export async function list(params) {
  const res = await fetch(APIs.system.dictbiz.list, {
    method: 'GET',
    params,
  });
  return res.data;
}
export async function parentList(params) {
  const res = await fetch(APIs.system.dictbiz.parentList, {
    method: 'GET',
    params,
  });
  return res.data;
}
export async function childList(params) {
  const res = await fetch(APIs.system.dictbiz.childList, {
    method: 'GET',
    params,
  });
  return res.data;
}
export async function submit(params) {
  const res = await fetch(APIs.system.dictbiz.submit, {
    method: 'POST',
    params,
  });
  return res.data;
}
export async function remove(params) {
  const res = await fetch(APIs.system.dictbiz.remove, {
    method: 'POST',
    params,
  });
  return res.data;
}
export async function detail(id) {
  const res = await fetch(APIs.system.dictbiz.detail, {
    method: 'GET',
    params: { id },
  });
  return res.data;
}
export async function tree() {
  const res = await fetch(APIs.system.dictbiz.tree, {
    method: 'GET',
    isSerialize: true,
    params: {
      code: 'DICT',
    },
  });
  return res.data;
}
export async function allTree(params?) {
  const res = await fetch(APIs.system.dictbiz.allTree, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}
export async function dictionary(params?) {
  const res = await fetch(APIs.system.dictbiz.dictionary, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}
export async function dictionaryList(params?) {
  const res = await fetch(APIs.system.dictbiz.dictionaryList, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}