import APIs from '../urls';
import fetch from '@/utils/fetch';

export async function page(params) {
  const data = await fetch(APIs.system.dict.page, params, 'get');
  return data;
}
export async function childList(params) {
  const data = await fetch(APIs.system.dict.childList, params, 'get');
  return data;
}
export async function save(params) {
  const data = await fetch(APIs.system.dict.save, params, 'post');
  return data;
}
export async function update(params) {
  const data = await fetch(APIs.system.dict.update, params, 'post');
  return data;
}
export async function remove(params) {
  const data = await fetch(APIs.system.dict.remove, params, 'post');
  return data;
}
export async function detail(params) {
  const data = await fetch(APIs.system.dict.detail, params, 'get');
  return data;
}
export async function tree(params?) {
  const res = await fetch(APIs.system.dict.tree, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}