import { createApp } from 'vue';
import App from './App.vue';
import store from '@/store';
import router from '@/router';
import locale from '@/locale';

import {
  message,
  Modal,
  notification,
} from 'ant-design-vue';
import 'ant-design-vue/lib/space/style/index.less';
import 'ant-design-vue/lib/message/style/index.less';
import 'ant-design-vue/lib/notification/style/index.less';
import 'ant-design-vue/lib/modal/style/index.less';


import { getBtnPerm } from '@/config/perms';
import reventReclick from '@/directives/prevent-reclick';
import currency from 'currency.js';

const app = createApp(App);

app.config.globalProperties.$message = message;
app.config.globalProperties.$notification = notification;
app.config.globalProperties.$modal = Modal;
app.config.globalProperties.$getBtnPerm = getBtnPerm;
app.config.globalProperties.$currency = currency;

app.directive('prevent-reclick', reventReclick);

app.use(router)
  .use(store)
  .use(locale)
  .mount('#app');
