import { defineStore } from 'pinia';
import { ConfigProvider } from 'ant-design-vue';
import { siteConfig } from '@/config/default.config';
import Cookies from 'js-cookie';
import { isBoolean } from 'lodash-es';

export const useSiteStore = defineStore('site', {
  state: () => {
    return {
      pageWidth: 1000,
      pageHeight: 500,
      lang: siteConfig.defaultLang, // 语言
      theme: siteConfig.defaultTheme, // 主题色
      collapsed: false, // 左侧菜单是否收起
      sideWidth: siteConfig.sideWidth, // 侧边栏宽度
      headerHeight: siteConfig.headerHeight, // 头部高度
    }
  },
  actions: {
    setLang(data: { key: string, lang: string }) {
      data = data || { key: siteConfig.defaultLang, lang: 'zh-CN' };
      const lang = data.key || siteConfig.defaultLang;
      localStorage.setItem('lang', lang);
      Cookies.set('locale', data.lang);
      this.lang = lang;
    },
    setTheme(color) {
      color = color || siteConfig.defaultTheme;
      localStorage.setItem('theme', color);
      this.theme = color;
      ConfigProvider.config({
        theme: {
          primaryColor: color,
        },
      });
    },
    toggleCollapsed(flag?: boolean) {
      if (isBoolean(flag)) {
        this.collapsed = flag;
      } else {
        this.collapsed = !this.collapsed;
      }
    },
    setPageWidth(width) {
      this.pageWidth = width;
    },
    setPageHeight(height) {
      this.pageHeight = height;
    }
  },
});