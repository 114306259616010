import APIs from './urls';
import fetch from '@/utils/fetch';
import { tenantId } from '@/config/default.config';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { getBrowserFingerprint } from '@/utils/util';

export async function token(params?: object) {
  try {
    const res = await fetch(APIs.auth.token, {
      method: 'POST',
      isSerialize: true,
      params: {
        ...getPCInfo(),
        ...params,
        tenantId: tenantId,
        grant_type: 'operate',
      },
    });
    if (res.access_token) {
      setToken(res.access_token);
    }
    return res;
  } catch (error) {
    removeToken();
    return Promise.reject(error);
  }
}
export async function refreshToken(params?: object) {
  try {
    const res = await fetch(APIs.auth.token, {
      method: 'POST',
      isSerialize: true,
      params: {
        ...getPCInfo(),
        ...params,
        tenantId: tenantId,
        grant_type: 'refresh_operate_token',
      },
    });
    if (res.access_token) {
      setToken(res.access_token);
    }
    return res;
  } catch (error) {
    removeToken();
    return Promise.reject(error);
  }
}

function getPCInfo() {
  return {
    pcName: '电脑',
    exeVer: `WEB围栏`,
    pcWinVer: '',
    pcMac: getBrowserFingerprint(),
    pcDiskserial: '',
  }
}