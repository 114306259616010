import { useSiteStore } from '@/store/site';

export default function useMenuCollapse() {
  const site = useSiteStore();
  const collapsed = computed(() => site.collapsed);

  function toggleCollapsed(flag?: boolean) {
    site.toggleCollapsed(flag);
  }

  return {
    collapsed,
    toggleCollapsed,
  }
}