import Cookies from 'js-cookie';
import { Base64 } from 'js-base64';
const TokenKey = 'access-token';
const RefreshTokenKey = 'refresh-token';
const tokenExpireKey = 'token-expire';

const { VITE_clientId, VITE_clientSecret, VITE_tokenHeader } = import.meta.env;

export function getToken() {
	return Cookies.get(TokenKey);
}

export function setToken(token) {
	return Cookies.set(TokenKey, token);
}

export function getRefreshToken() {
	return Cookies.get(RefreshTokenKey);
}

export function setRefreshToken(token) {
	return Cookies.set(RefreshTokenKey, token);
}

export function setExpiresIn(token) {
	return Cookies.set(tokenExpireKey, token);
}

export function getTokenExpire() {
	return Cookies.get(tokenExpireKey);
}

export function removeToken() {
	return Cookies.remove(TokenKey);
}

export function removeRefreshToken() {
	return Cookies.remove(RefreshTokenKey);
}

export function setUploadToken() {
	return {
		Authorization: `Basic ${Base64.encode(
			`${VITE_clientId}:${VITE_clientSecret}`,
		)}`,
		[VITE_tokenHeader]: 'bearer ' + getToken(),
	};
}