export default {
  beforeMount(el, binding) {
    el.disabled = false; // 初始化时启用按钮
    el.addEventListener('click', () => {
      el.disabled = true; // 点击后禁用按钮
      setTimeout(() => {
        el.disabled = false; // 在指定的时间后重新启用按钮
      }, binding.value || 1000); // 使用 binding.value 来设置等待时间，默认为 1000 毫秒
    });
  },
  unmounted(el) {
    // 组件卸载时移除事件监听器
    el.removeEventListener('click');
  },
}