import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { getBtns } from '@/apis/system/menu';
import { getMenu } from '@/apis/system/topmenu';
import { dropDown as carDropDown } from '@/apis/vehicle/car';
import { dropDown as driverDropDown } from '@/apis/vehicle/driver';

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      topMenu: localStorage.getItem('topmenu'),
      topMenuIndex: -1,
      topMenuList: [],
      menuList: [],
      perms: [],
      carList: [],
      driverList: [],
    }
  },
  getters: {
    getGlobalData: (state) => {
      return (key) => state[key] || [];
    },
  },
  actions: {
    setTopMenu(val) {
      this.topMenu = val;
      const idx = this.topMenuList.findIndex(item => item.code === val);
      this.topMenuIndex = idx;
    },
    setTopMenuIndex(val) {
      this.topMenuIndex = val;
    },
    setTopMenuList(val) {
      this.topMenuList = val;
    },
    setMenuList(val) {
      this.menuList = val;
    },
    setPerms(val) {
      this.perms = val;
      localStorage.setItem('perms', val);
    },
    setCarList(val) {
      this.carList = cloneDeep(val);
    },
    setDriverList(val) {
      this.driverList = cloneDeep(val);
    },
    getPerms() {
      return new Promise((resolve) => {
        getBtns().then((data) => {
          this.setPerms(data);
          resolve(data);
        });
      });
    },
    getMenus() {
      return new Promise((resolve) => {
        getMenu().then((data) => {
          this.setMenuList(data);
          resolve(data);
        });
      });
    },
    getCarList() {
      return new Promise((resolve) => {
        carDropDown({ isDept: 'no' }).then((data) => {
          this.setCarList(data);
          resolve(data);
        });
      });
    },
    getDriverList() {
      return new Promise((resolve) => {
        driverDropDown().then((data) => {
          this.setDriverList(data);
          resolve(data);
        });
      });
    },
  },
});