<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script lang="ts" setup>
import { baseURL } from '@/config/default.config';
import { getQueryString } from '@/utils/util';
import { useSiteStore } from '@/store/site';
import { langList } from '@/locale';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
// import enUS from 'ant-design-vue/es/locale/en_US';
// import jaJp from 'ant-design-vue/es/locale/ja_Jp';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

const site = useSiteStore();
const lang = computed(() => site.lang);
const locale = computed(() => {
  // if (lang.value === 'en') {
  //   return enUS;
  // } else if (lang.value === 'jp') {
  //   return jaJp;
  // }
  return zhCN;
});

// 监听页面显示
window.addEventListener('pageshow', (event) => {
  // event.persisted：从缓存获取数据时为true，否则为false
  // window.performance.navigation.type 如果用户通过浏览器后退访问的页面 则值为2
  if (
    event.persisted ||
    (window.performance && window.performance.navigation.type === 2)
  ) {
    window.location.reload(); // 刷新页面
  }
});

onMounted(() => {
  const debug = getQueryString('debug');
  const flag1: boolean = /^http(s?):\/\/localhost:(\d)/.test(location.href);
  const flag2: boolean = !/^http(s?):\/\/tms.benpao56.com/.test(location.href);
  if (!flag1) {
    if (debug === '1' || flag2) {
      new VConsole();
    }
  }
  console.warn('页面地址', window.location.origin);
  console.warn('接口地址', baseURL);
});

initLang();
initTheme();

// 加载语言
function initLang() {
  const lang = localStorage.getItem('lang');
  const obj = langList.find(item => item.key === lang);
  site.setLang(obj);
  if (lang === 'zh') {
    dayjs.locale('zh-cn');
  } else if (lang === 'en') {
    dayjs.locale('en-us');
  } else if (lang === 'jp') {
    console.log('lang', lang);
    dayjs.locale('ja-jp');
  } else {
    dayjs.locale('zh-cn');
  }
}

// 加载主题色
function initTheme() {
  const theme = localStorage.getItem('theme');
  site.setTheme(theme);
}

defineOptions({
  name: 'App',
});
</script>

<style lang="less">
@import '@/styles/var.css';
@import '@/styles/app.less';
@import '@/styles/resetAntd.less';
@import '@/styles/animation.less';
@import '@/styles/transition.less';
</style>