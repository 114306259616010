import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user_id: '',
      user_name: '',
      phone: '',
      dept_name: '',
      permissions: [],
      isFranchise: 0,
    }
  },
  actions: {
    setUserinfo(data) {
      this.user_id = data.user_id;
      this.user_name = data.user_name;
      this.phone = data.phone;
      this.dept_name = data.dept_name;
      this.permissions = data.permissions;
      this.isFranchise = data.isFranchise;
    },
  },
});