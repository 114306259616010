<template>
  <a-menu v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" theme="dark" mode="inline">
    <template v-for="(item) in menuList">
      <template v-if="item.children && item.children.length > 0">
        <a-sub-menu :key="item.code" :title="item.title">
          <template #icon v-if="item.icon">
            <IconFont :type="item.icon" />
          </template>
          <template v-for="(subitem) in item.children">
            <a-menu-item :key="subitem.path" @click="openPage(subitem)" v-if="hasPerm(subitem)">
              {{ subitem.title }}
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
      <a-menu-item :key="item.path" @click="openPage(item)" v-else-if="hasPerm(item)">
        <template #icon v-if="item.icon">
          <IconFont :type="item.icon" />
        </template>
        {{ item.title }}
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router';
import { getAllMenus } from '@/router/routes';
import { IconFont } from '@/config/default.config';
import { hasPerm } from '@/utils/util';

const { $message, $modal, $getBtnPerm } = getCurrentInstance().proxy;

const router = useRouter();
const route = useRoute();
const selectedKeys = ref<MenuType['id'][]>([]);
const openKeys = ref<MenuType['id'][]>([]);

const defaultMenu = [
  {
    title: '首页',
    code: 'home',
    path: '/home',
    icon: 'icon-shouye',
  },
];
const menuList = ref<MenuType[]>([...defaultMenu]);

handleRoute();

// 处理菜单展开和选中
async function handleRoute() {
  try {
    const menus = await getAllMenus();
    menuList.value = menuList.value.concat(menus);
    selectedKeys.value = [route.path];
    let pid = getParentPath(menus, route.path);
    if (!pid) {
      pid = toRaw(menus)[0].code;
    }
    openKeys.value = [pid];
  } catch (err) { }
}

// 递归获取父级菜单ID
function getParentPath(list, url) {
  list = toRaw(list);
  for (let i = 0, len = list.length; i < len; i++) {
    const item = list[i];
    if (item.path === url) {
      return item.pid;
    } else {
      if (item.children && item.children.length > 0) {
        const temp = getParentPath(item.children, url);
        if (temp) {
          return temp;
        }
      }
    }
  }
}

function openPage(menu: MenuType) {
  const data = toRaw(menu);
  // const perms = (localStorage.getItem('perms') || '').split(',');
  // if (!perms.includes(data.perm)) {
  //   $message.warning('你没有权限');
  //   return;
  // }
  router.push({ path: data.path });
}
</script>

<style lang="less" scoped>
:deep(.anticon) {
  font-size: 18px !important;
  color: #fff !important;
}
</style>