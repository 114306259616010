import APIs from '../urls';
import fetch from '@/utils/fetch';

export async function dropDown(params?) {
  const res = await fetch(APIs.vehicle.driver.dropDown, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}