import APIs from '../urls';
import fetch from '@/utils/fetch';

export async function list(params?) {
  const data = await fetch(APIs.system.menu.list, params, 'get');
  return data;
}
export async function tree(params?) {
  const data = await fetch(APIs.system.menu.tree, params, 'get');
  return data;
}
export async function detail(params) {
  const data = await fetch(APIs.system.menu.detail, params, 'get');
  return data;
}
export async function save(params) {
  const data = await fetch(APIs.system.menu.save, params, 'post');
  return data;
}
export async function update(params) {
  const data = await fetch(APIs.system.menu.update, params, 'post');
  return data;
}
export async function addBtns(params) {
  const data = await fetch(APIs.system.menu.addBtns, params, 'post');
  return data;
}
export async function getBtns(params?) {
  const data = await fetch(APIs.system.menu.getBtns, params, 'get');
  return data;
}
export async function remove(params) {
  const data = await fetch(APIs.system.menu.remove, params, 'post');
  return data;
}