import { RouteRecordRaw } from 'vue-router';
import { hasPerm, treeToFlat } from '@/utils/util';
import Layout from '@/layouts/index.vue';
import routesMap from './map';
import {
  fencePerm,
  fenceSendPerm,
  fenceCollectorsPerm,
  fenceCollectorPddPerm,
  fenceCollectorBpPerm,
  orderPerm,
  orderVolumnPerm,
  orderCancelPerm,
  orderPickPerm,
  orderPCPerm,
  orderPushPerm,
  financePerm,
  financeBasicPerm,
  financeDepreciatePerm,
  financeLongtermPerm,
  financeReceivablePerm,
  financePayablePerm,
  financeReportPerm,
  financeDepreciateReportPerm,
  financeUnamortizedReportPerm,
  financeReceivableReportPerm,
  financePayableReportPerm,
  userPerm,
  userPddPerm,
  controlPerm,
  controlDeptPerm,
  controlWechatPerm,
} from '@/config/perms';

import Redirect from '@/views/common/redirect.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'site',
    meta: {
      title: '首页',
    },
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/redirect/:path(.*)',
        component: Redirect,
      },
      {
        name: 'home',
        path: '/home',
        meta: {
          title: '首页',
        },
        component: routesMap['/home'],
      },
      {
        name: 'versionManage',
        path: '/system/version',
        meta: {
          title: '版本管理',
        },
        component: routesMap['/system/version'],
      },
      {
        name: '404',
        path: '/:pathMatch(.*)',
        meta: {
          title: '404',
        },
        component: routesMap['/nofound'],
      },
    ]
  },
  {
    name: 'login',
    path: '/login',
    meta: {
      title: '登录',
    },
    component: routesMap['/login'],
  },
  {
    name: 'download',
    path: '/download',
    meta: {
      isAuth: false,
      title: '下载',
    },
    component: routesMap['/download'],
  },
];

export const allRoutes: MenuType[] = [
  {
    title: '围栏管理',
    name: 'fence',
    code: 'fence',
    path: '/fence',
    parentName: 'site',
    perm: fencePerm,
    icon: 'icon-weilan',
    children: [
      {
        pid: 'fence',
        title: '电子围栏',
        name: 'fenceSend',
        code: 'fenceSend',
        path: '/fence/send',
        parentName: 'site',
        perm: fenceSendPerm,
        children: [
          {
            title: '电子围栏',
            name: 'fenceSendEdit',
            code: 'fenceSendEdit',
            path: '/fence/send/edit',
          },
          {
            title: '电子围栏',
            name: 'fenceSendDetail',
            code: 'fenceSendDetail',
            path: '/fence/send/detail',
          },
        ]
      },
      {
        pid: 'fence',
        title: '揽收区域（拼多多）',
        name: 'fenceCollector',
        code: 'fenceCollector',
        path: '/fence/collector/pdd',
        parentName: 'site',
        perm: [fenceCollectorsPerm, fenceCollectorPddPerm],
        children: [
          {
            title: '揽收区域（拼多多）',
            name: 'fenceCollectorEdit',
            code: 'fenceCollectorEdit',
            path: '/fence/collector/pdd/edit',
          },
          {
            title: '揽收区域（拼多多）',
            name: 'fenceCollectorDetail',
            code: 'fenceCollectorDetail',
            path: '/fence/collector/pdd/detail',
          },
          {
            title: '所有围栏区域（拼多多）',
            name: 'fenceCollectorAll',
            code: 'fenceCollectorAll',
            path: '/fence/collector/pdd/all',
          },
        ]
      },
      {
        pid: 'fence',
        title: '揽收区域（奔跑件）',
        name: 'fenceCollectorBp',
        code: 'fenceCollectorBp',
        path: '/fence/collector/bp',
        parentName: 'site',
        perm: fenceCollectorBpPerm,
        children: [
          {
            title: '揽收区域（奔跑件）',
            name: 'fenceCollectorBpEdit',
            code: 'fenceCollectorBpEdit',
            path: '/fence/collector/bp/edit',
          },
          {
            title: '揽收区域（奔跑件）',
            name: 'fenceCollectorBpDetail',
            code: 'fenceCollectorBpDetail',
            path: '/fence/collector/bp/detail',
          },
          {
            title: '所有围栏区域（奔跑件）',
            name: 'fenceCollectorBpAll',
            code: 'fenceCollectorBpAll',
            path: '/fence/collector/bp/all',
          },
        ]
      },
    ]
  },
  {
    title: '资产管理',
    name: 'finance',
    code: 'finance',
    path: '/finance',
    parentName: 'site',
    perm: financePerm,
    icon: 'icon-chengyunshangxianlujiage',
    children: [
      {
        pid: 'finance',
        title: '基础资料',
        name: 'financeBasic',
        code: 'financeBasic',
        path: '/finance/basic',
        parentName: 'site',
        perm: financeBasicPerm,
      },
      {
        pid: 'finance',
        title: '资产折旧',
        name: 'financeDepreciate',
        code: 'financeDepreciate',
        path: '/finance/depreciate',
        parentName: 'site',
        perm: financeDepreciatePerm,
      },
      {
        pid: 'finance',
        title: '长期待摊',
        name: 'financeUnamortized',
        code: 'financeUnamortized',
        path: '/finance/unamortized',
        parentName: 'site',
        perm: financeLongtermPerm,
      },
      {
        pid: 'finance',
        title: '应收租金',
        name: 'financeReceivable',
        code: 'financeReceivable',
        path: '/finance/receivable',
        parentName: 'site',
        perm: financeReceivablePerm,
      },
      {
        pid: 'finance',
        title: '应付租金',
        name: 'financePayable',
        code: 'financePayable',
        path: '/finance/payable',
        parentName: 'site',
        perm: financePayablePerm,
      },
    ]
  },
  {
    title: '资产报表',
    name: 'financeReport',
    code: 'financeReport',
    path: '/financeReport',
    parentName: 'site',
    perm: financeReportPerm,
    icon: 'icon-baobiao',
    children: [
      {
        pid: 'financeReport',
        title: '资产折旧报表',
        name: 'financeDepreciateReport',
        code: 'financeDepreciateReport',
        path: '/finance/report/depreciate',
        parentName: 'site',
        perm: financeDepreciateReportPerm,
      },
      {
        pid: 'financeReport',
        title: '长期摊销报表',
        name: 'financeUnamortizedReport',
        code: 'financeUnamortizedReport',
        path: '/finance/report/unamortized',
        parentName: 'site',
        perm: financeUnamortizedReportPerm,
      },
      {
        pid: 'financeReport',
        title: '应收租金报表',
        name: 'financeReceivableReport',
        code: 'financeReceivableReport',
        path: '/finance/report/receivable',
        parentName: 'site',
        perm: financeReceivableReportPerm,
      },
      {
        pid: 'financeReport',
        title: '应付租金报表',
        name: 'financePayableReport',
        code: 'financePayableReport',
        path: '/finance/report/payable',
        parentName: 'site',
        perm: financePayableReportPerm,
      },
    ]
  },
  {
    title: '订单相关',
    name: 'order',
    code: 'order',
    path: '/order',
    parentName: 'site',
    icon: 'icon-link',
    perm: orderPerm,
    children: [
      {
        pid: 'order',
        title: '货物体积',
        name: 'orderVolume',
        code: 'orderVolume',
        path: '/order/volume',
        parentName: 'site',
        perm: orderVolumnPerm,
      },
      {
        pid: 'order',
        title: '订单取消',
        name: 'orderCancel',
        code: 'orderCancel',
        path: '/order/cancel',
        parentName: 'site',
        perm: orderCancelPerm,
      },
      {
        pid: 'order',
        title: '订单提货',
        name: 'orderPick',
        code: 'orderPick',
        path: '/order/pick',
        parentName: 'site',
        perm: orderPickPerm,
      },
      {
        pid: 'order',
        title: 'PC码查询',
        name: 'searchPC',
        code: 'searchPC',
        path: '/order/searchPC',
        parentName: 'site',
        perm: orderPCPerm,
      },
      {
        pid: 'order',
        title: '推送查询',
        name: 'pddPush',
        code: 'pddPush',
        path: '/order/pddPush',
        parentName: 'site',
        perm: orderPushPerm,
      },
    ]
  },
  {
    title: '常用工具',
    name: 'tools',
    code: 'tools',
    path: '/tools',
    parentName: 'site',
    icon: 'icon-tishi',
    children: [
      {
        pid: 'tools',
        title: '图片压缩（本地）',
        name: 'compressImage',
        code: 'compressImage',
        path: '/tools/compressImage',
        parentName: 'site',
      },
      {
        pid: 'tools',
        title: '图片压缩（上传）',
        name: 'compressImage2',
        code: 'compressImage2',
        path: '/tools/compressImage2',
        parentName: 'site',
      },
      {
        pid: 'tools',
        title: '生成二维码',
        name: 'makeQrcode',
        code: 'makeQrcode',
        path: '/tools/makeQrcode',
        parentName: 'site',
      },
      {
        pid: 'tools',
        title: '音频转换',
        name: 'audioFormat',
        code: 'audioFormat',
        path: '/tools/audioFormat',
        parentName: 'site',
      },
    ]
  },
  {
    title: '账号管理',
    name: 'system',
    code: 'system',
    path: '/system',
    parentName: 'site',
    icon: 'icon-xitongguanli',
    perm: userPerm,
    children: [
      {
        pid: 'system',
        title: '拼多多账号',
        name: 'systemUserPdd',
        code: 'systemUserPdd',
        path: '/system/user/pdd',
        parentName: 'site',
        perm: userPddPerm,
      },
    ]
  },
  {
    title: '配置管理',
    name: 'control',
    code: 'control',
    path: '/control',
    parentName: 'site',
    icon: 'icon-xitongguanli',
    perm: controlPerm,
    children: [
      {
        pid: 'control',
        title: '网点配置',
        name: 'controlDept',
        code: 'controlDept',
        path: '/control/dept',
        parentName: 'site',
        perm: controlDeptPerm,
      },
      {
        pid: 'control',
        title: '小程序配置',
        name: 'controlWechat',
        code: 'controlWechat',
        path: '/control/wechat',
        parentName: 'site',
        perm: controlWechatPerm,
      },
    ]
  }
];

export function getAllRoutes() {
  return treeToFlat(allRoutes, 'code');
}

export function getAllMenus() {
  const perms = (localStorage.getItem('perms') || '').split(',');
  return allRoutes.filter(item => {
    return hasPerm(item);
  }).map(item => {
    const { children, ...newItem } = item;
    const _child = item.children.map(subitem => {
      const { children, ...newSubItem } = subitem;
      return {
        ...newSubItem,
      }
    });
    return {
      ...newItem,
      children: _child,
    }
  });
}

// export const printRoutes = [
//   {
//     id: 'print',
//     title: '打印模板',
//     name: 'print',
//     code: 'print',
//     path: '/print',
//     icon: 'icon-dayinji',
//     parentName: 'site',
//   },
//   {
//     id: 'print-edit',
//     title: '打印模板',
//     name: 'printEdit',
//     code: 'printEdit',
//     path: '/printEdit',
//   },
// ];

export default routes;