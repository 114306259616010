export default {
  '/home': () => import(`@/views/home.vue`),
  '/login': () => import(`@/views/common/login.vue`),
  '/nofound': () => import(`@/views/common/nofound.vue`),
  '/download': () => import(`@/views/download/index.vue`),

  '/system/version': () => import(`@/views/system/version/index.vue`),

  '/control/dept': () => import('@/views/control/dept.vue'),
  '/control/wechat': () => import('@/views/control/wechat.vue'),

  '/print': () => import(`@/views/print/index.vue`),
  '/tools/compressImage': () => import(`@/views/tools/compressImage.vue`),
  '/tools/compressImage2': () => import(`@/views/tools/compressImage2.vue`),
  '/tools/makeQrcode': () => import(`@/views/tools/makeQrcode.vue`),
  '/tools/audioFormat': () => import(`@/views/tools/audioFormat.vue`),

  '/order/volume': () => import(`@/views/order/volume/index.vue`),
  '/order/cancel': () => import(`@/views/order/cancel/index.vue`),
  '/order/pick': () => import(`@/views/order/pick/index.vue`),
  '/order/searchPC': () => import(`@/views/order/searchPC/index.vue`),
  '/order/pddPush': () => import(`@/views/order/pddPush/index.vue`),

  '/fence/send': () => import(`@/views/fence/send/index.vue`),
  '/fence/send/edit': () => import(`@/views/fence/send/edit.vue`),
  '/fence/send/detail': () => import(`@/views/fence/send/detail.vue`),

  '/fence/collector/pdd': () => import(`@/views/fence/collector/index.vue`),
  '/fence/collector/pdd/edit': () => import(`@/views/fence/collector/edit.vue`),
  '/fence/collector/pdd/detail': () => import(`@/views/fence/collector/detail.vue`),
  '/fence/collector/pdd/all': () => import(`@/views/fence/collector/all.vue`),

  '/fence/collector/bp': () => import(`@/views/fence/collector/index.vue`),
  '/fence/collector/bp/edit': () => import(`@/views/fence/collector/edit.vue`),
  '/fence/collector/bp/detail': () => import(`@/views/fence/collector/detail.vue`),
  '/fence/collector/bp/all': () => import(`@/views/fence/collector/all.vue`),

  '/finance/basic': () => import(`@/views/finance/basic/index.vue`),
  '/finance/depreciate': () => import(`@/views/finance/depreciate/index.vue`),
  '/finance/unamortized': () => import(`@/views/finance/unamortized/index.vue`),
  '/finance/receivable': () => import(`@/views/finance/receivable/index.vue`),
  '/finance/payable': () => import(`@/views/finance/payable/index.vue`),

  '/finance/report/depreciate': () => import(`@/views/finance/report/depreciate.vue`),
  '/finance/report/unamortized': () => import(`@/views/finance/report/unamortized.vue`),
  '/finance/report/payable': () => import(`@/views/finance/report/payable.vue`),
  '/finance/report/receivable': () => import(`@/views/finance/report/receivable.vue`),

  '/system/user/pdd': () => import(`@/views/system/user/pdd.vue`),

  // '/system/admin': () => import(`@/views/system/admin/index.vue`),
  // '/system/dict': () => import(`@/views/system/dict/index.vue`),
  // '/system/dictbiz': () => import(`@/views/system/dictbiz/index.vue`),
  // '/system/topmenu': () => import(`@/views/system/topmenu/index.vue`),
  // '/system/menu': () => import(`@/views/system/menu/index.vue`),
  // '/system/role': () => import(`@/views/system/role/index.vue`),
}