import APIs from '../urls';
import fetch from '@/utils/fetch';

export async function list(params) {
  const res = await fetch(APIs.system.dept.list, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}
export async function lazyList(params) {
  const res = await fetch(APIs.system.dept.lazyList, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}
export async function detail(params) {
  const res = await fetch(APIs.system.dept.detail, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}
export async function tree(params?) {
  const res = await fetch(APIs.system.dept.tree, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}
export async function lazyTree(params) {
  const res = await fetch(APIs.system.dept.lazyTree, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}
export async function allList(params) {
  const res = await fetch(APIs.system.dept.allList, {
    method: 'GET',
    isSerialize: true,
    params,
  });
  return res.data;
}