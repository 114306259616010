<template>
  <div class="page">
    <a-spin size="large" />
  </div>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const path = route.params.path;
    router.replace({ path: '/' + path, query: route.query });
  }
});
</script>

<style lang="less" scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>