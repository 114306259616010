import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { tree } from '@/apis/system/dept';

export const useDeptStore = defineStore('dept', {
  state: () => {
    return {
      treeDept: [],
    }
  },
  actions: {
    setTreeDept(val) {
      this.treeDept = cloneDeep(val);
    },
    getTreeDept() {
      return new Promise((resolve) => {
        tree().then((data) => {
          this.setTreeDept(data);
          resolve(data);
        });
      });
    },
  },
});