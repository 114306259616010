import APIs from '../urls';
import fetch from '@/utils/fetch';

export async function list(params?) {
  const data = await fetch(APIs.system.topmenu.list, params, 'get');
  return data;
}
export async function detail(params) {
  const data = await fetch(APIs.system.topmenu.detail, params, 'get');
  return data;
}
export async function save(params) {
  const data = await fetch(APIs.system.topmenu.save, params, 'post');
  return data;
}
export async function update(params) {
  const data = await fetch(APIs.system.topmenu.update, params, 'post');
  return data;
}
export async function setMenu(params) {
  const data = await fetch(APIs.system.topmenu.setMenu, params, 'post');
  return data;
}
export async function getMenu(params?) {
  const data = await fetch(APIs.system.topmenu.getMenu, params, 'get');
  return data;
}
export async function remove(params) {
  const data = await fetch(APIs.system.topmenu.remove, params, 'post');
  return data;
}