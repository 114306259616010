export default {
  global: {
    userCenter: '個人センター',
    userSetting: '個人設定',
    versionManage: 'バージョン管理',
    logout: '終了アカウント',
  },
  login: {
    headerText: 'Ant Design は西湖区で最も影響力のあるWeb設計規範である',
    tabAccount: 'アカウントログイン',
    tabPhone: '携帯番号登録',
    btn: 'ログイン',
    getcode: '認証コードの取得',
    remember: 'パスワードを記憶する',
    forgot: 'パスワードを忘れる',
    usernameRule: 'ユーザー名を入力してください！',
    passwordRule: 'パスワードを入力してください！',
    phoneRule: '携帯番号を入力してください！',
    codeRule: '認証コードを入力してください！',
    usernamePlaceholder: 'アカウント：admin',
    passwordPlaceholder: 'パスワード：adminまたは123456',
    phoneNumPlaceholder: '携帯番号',
    phoneCodePlaceholder: '認証コード',
    loginFail: 'ログインに失敗しました',
  },
}