import { defineStore } from 'pinia';
import { deepChangeKey } from '@/utils/util';
import { tree as dictTree } from '@/apis/system/dict';
import { allTree } from '@/apis/system/dictbiz';

interface DictDataItem {
  code: string,
  dictKey: string,
  dictValue: string,
  children?: DictDataItem[],
}

export const useDictStore = defineStore('dict', {
  state: () => {
    return {
      dictList: [],
      dictbizList: [],
      dictMap: {}, // 系统字典
      dictbizMap: {}, // 业务字典
    }
  },
  getters: {
    getDictByCode: (state) => {
      return (code, type: number) => {
        if (type === 0) {
          return state.dictMap[code] || [];
        } else {
          return state.dictbizMap[code] || [];
        }
      };
    },
  },
  actions: {
    setAllDicts(val: Array<DictDataItem>, type: number) {
      let dictMap = {}; // 保存到Map对象
      val.forEach((item) => {
        dictMap[item.code] = deepChangeKey(item.children, {
          label: 'dictValue',
          value: 'dictKey',
        });
      });
      if (type === 0) {
        this.dictMap = dictMap;
        this.dictList = deepChangeKey(val, {
          label: 'dictValue',
          value: 'dictKey',
        });
      } else {
        this.dictbizMap = dictMap;
        this.dictbizList = deepChangeKey(val, {
          label: 'dictValue',
          value: 'dictKey',
        });
      }
    },
    getSystemTree() {
      return new Promise((resolve) => {
        dictTree().then((data) => {
          this.setAllDicts(data, 0);
          resolve(data);
        });
      });
    },
    getBizTree() {
      return new Promise((resolve) => {
        allTree().then((data) => {
          this.setAllDicts(data, 1);
          resolve(data);
        });
      });
    },
  },
});