export default {
  system: {
    admin: {},
    file: {
      page: '/running-resource/attach/page',
      upload: '/running-resource/oss/endpoint/put-file',
      uploadNew: '/running-resource/oss/endpoint/put-file-attach',
      removeNew: '/running-resource/oss/endpoint/remove-file-attach', // 用fileId删除
    },
    role: {},
    menu: {},
    dict: {
      list: '/running-system/dict/list',
      parentList: '/running-system/dict/parent-list',
      childList: '/running-system/dict/child-list',
      remove: '/running-system/dict/remove',
      submit: '/running-system/dict/submit',
      detail: '/running-system/dict/detail',
      tree: '/running-system/dict/tree',
      dictionary: '/running-system/dict/dictionary',
    },
    dictbiz: {
      list: '/running-system/dict-biz/list',
      parentList: '/running-system/dict-biz/parent-list',
      childList: '/running-system/dict-biz/child-list',
      remove: '/running-system/dict-biz/remove',
      submit: '/running-system/dict-biz/submit',
      detail: '/running-system/dict-biz/detail',
      tree: '/running-system/dict-biz/tree',
      allTree: '/running-system/dict-biz/all-tree',
      dictionary: '/running-system/dict-biz/dictionary',
      dictionaryList: '/running-system/dict-biz/dictionaryList', // 根据code获取字典
    },
    dept: {
      list: '/running-system/dept/list',
      lazyList: '/running-system/dept/lazy-list',
      detail: '/running-system/dept/detail',
      tree: '/running-system/dept/tree',
      lazyTree: '/running-system/dept/lazy-tree',
      allList: '/running-system/dept/all_list',
    },
    tmsuser: {
      page: '/running_tms_platform/tms_user/page',
      save: '/running_tms_platform/tms_user/save',
      updateName: '/running_tms_platform/tms_user/update/username',
      setPwd: '/running_tms_platform/tms_user/update/password',
      enable: '/running_tms_platform/tms_user/update/Enable',
      stop: '/running_tms_platform/tms_user/update/stop',
    },
    version: {
      page: '/running-app-waybill/version/page',
      save: '/running-app-waybill/version/save',
      update: '/running-app-waybill/version/update',
      detail: '/running-app-waybill/version/detail',
    },
  },
  auth: {
    token: '/running-auth/app/oauth/token',
    updatePwd: '/running-tms-system/rightUser/update-pwd', // 修改密码
  },
  driver: {
    dropDown: '/running-driver/driverInfo/dropDown'
  },
  vehicle: {
    car: {
      dropDown: '/running-car/car_info/dropDown-userDept',
    },
    driver: {
      dropDown: '/running-driver/driverInfo/dropDown'
    },
  },
  fence: {
    send: {
      page: '/running_platform/fence/page',
      detail: '/running_platform/fence/detail',
      save: '/running_platform/fence/save',
      update: '/running_platform/fence/update',
      remove: '/running_platform/fence/remove/fence',
      removeAll: '/running_platform/fence/remove/all',
      enable: '/running_platform/fence/enable',
      stop: '/running_platform/fence/stop',
    },
    collector: {
      page: '/running_platform/driver/fence/page',
      detail: '/running_platform/driver/fence/detail',
      save: '/running_platform/driver/fence/save',
      update: '/running_platform/driver/fence/update',
      remove: '/running_platform/driver/fence/remove/fence',
      removeAll: '/running_platform/driver/fence/remove/all',
      enable: '/running_platform/driver/fence/enable',
      stop: '/running_platform/driver/fence/stop',
      all: '/running_platform/driver/fence/all',
      refresh: '/running_platform/driver/fence/refresh/order',
    },
  },
  finance: {
    basic: {
      page: '/running-property/type/page',
      save: '/running-property/type/save',
      update: '/running-property/type/update',
      detail: '/running-property/type/detail',
      remove: '/running-property/type/remove',
      parent: '/running-property/type/parent',
      code: '/running-property/type/code',
      grantTree: '/running-property/type/grant-tree',
    },
    property: {
      page: '/running-property/regular/page',
      save: '/running-property/regular/save',
      update: '/running-property/regular/update',
      remove: '/running-property/regular/remove',
      detail: '/running-property/regular/detail',
      logs: '/running-property/regular/log/page',
      adjust: '/running-property/regular/adjust',
      depress: '/running-property/regular/depress/list',
      termIn: '/running-property/regular/termIn/depress',
      manualDepress: '/running-property/regular/depress/manual',
      reportForm: '/running-property/regular/report_form',
      nowReportForm: '/running-property/regular/report_form/new',
      ZuJingReportForm: '/running-property/regular/report_form/zujing',
      audit: '/running-property/regular/audit',
      exportProperty: '/running-property/regular/export/property',
      exportUnamortized: '/running-property/regular/export/unamortized',
      exportReceive: '/running-property/regular/export/receive',
      exportDeal: '/running-property/regular/export/deal',
      propertyImportTemplate: '/running-property/regular/property/import-template',
      propertyImport: '/running-property/regular/property/import',
      unamortizedImportTemplate: '/running-property/regular/unamortized/import-template',
      unamortizedImport: '/running-property/regular/unamortized/import',
      exportPropertyInfo: '/running-property/regular/export/property/info',
      exportUnamortizedInfo: '/running-property/regular/export/unamortized/info',
      exportReceiveInfo: '/running-property/regular/export/receive/info',
      exportDealInfo: '/running-property/regular/export/deal/info',
    },
  },
  waybill: {
    order: {
      pc: '/running-app-waybill/order/pdd/pc',
      pddPush: '/running_tms_platform/base/pdd-push/page',
      pddPushList: '/running_tms_platform/base/pdd-push/info',
    }
  }
}